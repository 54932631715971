



































import { Component, Prop, Vue } from "vue-property-decorator";
import CompanyProject from "@/components/CompanyProject.vue";

// Company interface
interface CompanyDto {
    prefix: string;
    name: string;
    img: string;
    periods: Array<string>;
    description: string;
    projects?: Array<object>;
}

@Component({
    components: {
        CompanyProject
    }
})
export default class Company extends Vue {
    @Prop({ required: true }) readonly company!: CompanyDto;

    // Is theme dark active?
    get isThemeDark(): boolean {
		return this.$vuetify.theme.dark;
	}

    // Get proper class
    get companyLogo(): string {
        return `mx-auto mt-2 rounded-lg ${this.company.prefix}-${this.isThemeDark ? 'dark' : 'light'}-logo`;
    }

    // Get divider background color
    get dividerColor(): string {
        return `background-color: ${this.$vuetify.theme.currentTheme.accent};`;
    }

    // Get margin of company content
    get contentMargin(): string {
        let classes = 'company-content';

        if (this.company.projects?.length) {
            classes += ' company-has-projects';
        }
        else if (this.company.prefix == 'in') {
            classes += ' last-company';
        }

        return classes;
    }

    // Get margin of company projects
    get projectsMargin(): string {
        if (this.$vuetify.breakpoint.smAndDown)
            return 'margin-top: -10rem;'
        
        return 'margin-top: -9rem;';
    }
}
