















import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";
import Company from "@/components/Company.vue";

import firebase from "firebase/app";

@Component ({
    metaInfo(): MetaInfo {
        firebase.analytics().logEvent("page_view");
        firebase.analytics().logEvent("screen_view", {
            'app_name': process.env.VUE_APP_PROJECT,
            'app_type': process.env.VUE_APP_TYPE,
            'screen_name': 'Experience',
            'app_version': process.env.VUE_APP_VERSION
        });

        return {
            title: 'Experience'
        };
    },

    components: {
        Company
    },
})
export default class Experience extends Vue {
    companies = [
        {
            prefix: 'lss',
            name: 'Lion Systems Solutions',
            img: 'lss.svg',
            periods: ['April 2019 - To date'],
            description: "I've been developing a lot of ERP & CRM systems for many customers (big and small) each one with different features as the customer needed. I did the informative website for the company and I've been working in powerful projects like online stores and ecommerce platforms with API development and dev ops.",
            projects: [
                {
                    name: 'Ecommerce Platform',
                    img: 'dashboard.png',
                    text: "Connected with platforms like Amazon and Mercado Libre to publish products generated by web scraping and managed by this system",
                    tools: [
                        { name: 'Laravel', icon: 'mdi-laravel', color: 'red' },
                        { name: 'Vue', icon: 'mdi-vuejs', color: 'green darken-1' },
                        { name: 'Vuetify', icon: 'mdi-vuetify', color: 'blue darken-1' },
                        { name: 'Python', icon: 'mdi-language-python', color: 'yellow darken-2' },
                        { name: 'API Development', icon: 'mdi-api', color: 'brown lighten-2' },
                    ]
                },

                {
                    name: 'Online Store',
                    img: 'store.png',
                    text: "Made with docker and powered by gitlab dev ops",
                    tools: [
                        { name: 'Prestashop', avatar: 'prestashop.png', color: 'pink' },
                        { name: 'Docker', icon: 'mdi-docker', color: 'cyan darken-1' },
                        { name: 'GitLab DevOps ', icon: 'mdi-gitlab', color: 'orange darken-3' },
                        { name: 'PHP', icon: 'mdi-language-php', color: 'indigo' },
                    ]
                },

                {
                    name: "ERP & CRM System",
                    img: 'erp.png',
                    text: "Powered by modules, which bring some features like docs digital signed, invoices, orders, proposals, shipments, HRM, etcetera",
                    tools: [
                        { name: 'PHP', icon: 'mdi-language-php', color: 'indigo' },
                        { name: 'Javascript', icon: 'mdi-language-javascript', color: 'yellow darken-3' },
                        { name: 'SQL', icon: 'mdi-database',  },
                        { name: 'Slack', icon: 'mdi-slack', color: 'pink darken-2' }
                    ]
                },

                {
                    name: "Company website",
                    img: 'website.png',
                    text: "Beautiful basic website with VR section of soccer stadium implemented with AFrame",
                    tools: [
                        { name: 'HTML 5', icon: 'mdi-language-html5', color: 'orange' },
                        { name: 'CSS', icon: 'mdi-language-css3', color: 'blue darken-1' },
                        { name: 'JQuery', icon: 'mdi-jquery', color: 'indigo lighten-1' },
                        { name: 'Bootstrap', icon: 'mdi-bootstrap', color: 'deep-purple darken-3' },
                        { name: 'AFrame', icon: 'mdi-alpha-a-box', color: 'pink darken-2' }
                    ],
                }
            ]
        },
        {
            prefix: 'in',
            name: 'INEGI',
            img: 'inegi.png',
            periods: ['April 2015 - September 2015', 'March 2016 - June 2016'],
            description: 'I was part of this company as internship twice, in my first period I cleaned, repair and configured all staff computers and others devices as needed. In my last period I upgraded with new frameworks the intranet site for Aguascalientes offices to manage all features of HRM area and a proxy to restrict access to specific websites.',
        }
    ];
}
